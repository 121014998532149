// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap-4.6.2/scss/bootstrap';

//dashboard
@import 'dashboard';
@import "partials/invoice";


.page-link{
    border: none !important;
    line-height: 1;
}
